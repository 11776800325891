import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "expanded_menu" ]

  open() {
    this.expanded_menuTarget.style.visibility = 'visible'
  }

  close() {
    this.expanded_menuTarget.style.visibility = 'hidden'
  }
}
