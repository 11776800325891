import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slide" ]

  connect() {
    this.currentPane = 0
    this.maxPanes = this.slideTargets.length - 1

    for (const slide of this.slideTargets) {
      slide.style.display = 'none'
    }
    this.slideTargets[this.currentPane].style.display = 'block'
  }

  next() {
    if(this.currentPane == this.maxPanes) {
      this.slideTargets[this.currentPane].style.display = 'none'
      this.currentPane = 0
      this.slideTargets[this.currentPane].style.display = 'block'
    } else {
      this.slideTargets[this.currentPane].style.display = 'none'
      this.currentPane += 1
      this.slideTargets[this.currentPane].style.display = 'block'
    }
  }

  prev() {
    if(this.currentPane == 0) {
      this.slideTargets[this.currentPane].style.display = 'none'
      this.currentPane = this.maxPanes
      this.slideTargets[this.currentPane].style.display = 'block'
    } else {
      this.slideTargets[this.currentPane].style.display = 'none'
      this.currentPane -= 1
      this.slideTargets[this.currentPane].style.display = 'block'
    }
  }
}
