import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pane" ]

  show(event) {
    this.hideAll()
    document.getElementById(event.target.dataset.pane).classList.remove("hidden")
    document.getElementById(event.target.dataset.pane).classList.add("show")
  }

  hideAll() {
    var index
    for (index in this.paneTargets) {
      console.log('Hiding pane')
      this.paneTargets[index].classList.add("hidden")
      this.paneTargets[index].classList.remove("show")
    }
  }
}
