import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: Number }

  updateQuantity(e) {
    let qty = e.target.value

    fetch("/cart/cart_items/"+this.idValue+"?qty="+qty, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(function(result) {
      location.reload()
    })
  }
}
