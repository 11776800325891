import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search_menu" ]

  open() {
    this.search_menuTarget.style.visibility = 'visible'
  }

  close() {
    this.search_menuTarget.style.visibility = 'hidden'
  }
}
