import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "required", "commit" ]

  connect() {
    this.element.setAttribute('novalidate', true)
    this.checkRequiredFields()
  }

  checkRequiredFields() {
    let fields_are_not_blank = this.requiredTargets.every(f => f.value != '')

    if (fields_are_not_blank) {
      this.commitTarget.disabled = false
    } else {
      this.commitTarget.disabled = true
    }
  }
}
