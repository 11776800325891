import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "country",
    "tfState",
    "usState",
    "caProvince",
    "auState",
    "countyLb",
    "stateOLb",
    "stateLb",
    "provinceLb",
    "postcodeLb",
    "postcodeOLb",
    "zipLb"
  ]

  connect() {
    this.format()
  }

  format() {
    switch (this.countryTarget.value) {
      case 'AU':
        this.formatForAustralia()
        break
      case 'CA':
        this.formatForCanada()
        break
      case 'GB':
        this.formatForUnitedKingdom()
        break
      case 'US':
        this.formatForUnitedStates()
        break
      default:
        this.formatForDefault()
    }
  }

  formatForAustralia() {
    this.showAUState()
    this.showState()
    this.showPostcode()
  }

  formatForCanada() {
    this.showCAProvince()
    this.showProvince()
    this.showPostcode()
  }

  formatForUnitedKingdom() {
    this.showTextState()
    this.showCounty()
    this.showPostcode()
  }

  formatForUnitedStates() {
    this.showUSState()
    this.showState()
    this.showZIP()
  }

  formatForDefault() {
    this.showTextState()
    this.showOptionalState()
    this.showOptionalPostcode()
  }

  showCounty() {
    this.countyLbTarget.style.display = 'block'
    this.stateOLbTarget.style.display = 'none'
    this.stateLbTarget.style.display = 'none'
    this.provinceLbTarget.style.display = 'none'
  }

  showOptionalState() {
    this.countyLbTarget.style.display = 'none'
    this.stateOLbTarget.style.display = 'block'
    this.stateLbTarget.style.display = 'none'
    this.provinceLbTarget.style.display = 'none'
  }

  showState() {
    this.countyLbTarget.style.display = 'none'
    this.stateOLbTarget.style.display = 'none'
    this.stateLbTarget.style.display = 'block'
    this.provinceLbTarget.style.display = 'none'
  }

  showProvince() {
    this.countyLbTarget.style.display = 'none'
    this.stateOLbTarget.style.display = 'none'
    this.stateLbTarget.style.display = 'none'
    this.provinceLbTarget.style.display = 'block'
  }

  showTextState() {
    this.tfStateTarget.style.display = 'block'
    this.tfStateTarget.disabled = false

    this.usStateTarget.style.display = 'none'
    this.usStateTarget.disabled = true

    this.caProvinceTarget.style.display = 'none'
    this.caProvinceTarget.disabled = true

    this.auStateTarget.style.display = 'none'
    this.auStateTarget.disabled = true
  }

  showAUState() {
    this.tfStateTarget.style.display = 'none'
    this.tfStateTarget.disabled = true

    this.usStateTarget.style.display = 'none'
    this.usStateTarget.disabled = true

    this.caProvinceTarget.style.display = 'none'
    this.caProvinceTarget.disabled = true

    this.auStateTarget.style.display = 'block'
    this.auStateTarget.disabled = false
  }

  showCAProvince() {
    this.tfStateTarget.style.display = 'none'
    this.tfStateTarget.disabled = true

    this.usStateTarget.style.display = 'none'
    this.usStateTarget.disabled = true

    this.caProvinceTarget.style.display = 'block'
    this.caProvinceTarget.disabled = false

    this.auStateTarget.style.display = 'none'
    this.auStateTarget.disabled = true
  }

  showUSState() {
    this.tfStateTarget.style.display = 'none'
    this.tfStateTarget.disabled = true

    this.usStateTarget.style.display = 'block'
    this.usStateTarget.disabled = false

    this.caProvinceTarget.style.display = 'none'
    this.caProvinceTarget.disabled = true

    this.auStateTarget.style.display = 'none'
    this.auStateTarget.disabled = true
  }

  showPostcode() {
    this.postcodeLbTarget.style.display = 'block'
    this.postcodeOLbTarget.style.display = 'none'
    this.zipLbTarget.style.display = 'none'
  }

  showOptionalPostcode() {
    this.postcodeLbTarget.style.display = 'none'
    this.postcodeOLbTarget.style.display = 'block'
    this.zipLbTarget.style.display = 'none'
  }

  showZIP() {
    this.postcodeLbTarget.style.display = 'none'
    this.postcodeOLbTarget.style.display = 'none'
    this.zipLbTarget.style.display = 'block'
  }
}
