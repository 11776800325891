import { Controller } from "stimulus"

export default class extends Controller {
  set_billing(event) {
    if (event.target.value != '') {
      fetch('/settings/addresses/'+event.target.value+'.json', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(resp => resp.json())
      .then(json => {
        document.getElementById('order_billing_address_attributes_address_line1').value = json.address_line1
        document.getElementById('order_billing_address_attributes_address_line2').value = json.address_line2
        document.getElementById('order_billing_address_attributes_address_city').value = json.address_city
        document.getElementById('order_billing_address_attributes_address_state').value = json.address_state
        document.getElementById('order_billing_address_attributes_address_postal_code').value = json.address_postal_code
        document.getElementById('order_billing_address_attributes_address_country').value = json.address_country
      })
    }
  }

  set_shipping(event) {
    if (event.target.value != '') {
      fetch('/settings/addresses/'+event.target.value+'.json', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(resp => resp.json())
      .then(json => {
        document.getElementById('order_shipping_address_attributes_address_line1').value = json.address_line1
        document.getElementById('order_shipping_address_attributes_address_line2').value = json.address_line2
        document.getElementById('order_shipping_address_attributes_address_city').value = json.address_city
        document.getElementById('order_shipping_address_attributes_address_state').value = json.address_state
        document.getElementById('order_shipping_address_attributes_address_postal_code').value = json.address_postal_code
        document.getElementById('order_shipping_address_attributes_address_country').value = json.address_country
      })
    }
  }

  copy_address(event) {
    if (event.target.checked) {
      document.getElementById('order_shipping_address_id').value = document.getElementById('order_billing_address_id').value
      document.getElementById('order_shipping_address_attributes_address_line1').value = document.getElementById('order_billing_address_attributes_address_line1').value
      document.getElementById('order_shipping_address_attributes_address_line2').value = document.getElementById('order_billing_address_attributes_address_line2').value
      document.getElementById('order_shipping_address_attributes_address_city').value = document.getElementById('order_billing_address_attributes_address_city').value
      document.getElementById('order_shipping_address_attributes_address_state').value = document.getElementById('order_billing_address_attributes_address_state').value
      document.getElementById('order_shipping_address_attributes_address_postal_code').value = document.getElementById('order_billing_address_attributes_address_postal_code').value
      document.getElementById('order_shipping_address_attributes_address_country').value = document.getElementById('order_billing_address_attributes_address_country').value
    }
  }
}
