import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pane" ]
  static values = { state: String }

  connect() {
    if(this.stateValue == 'open') {
      this.paneTarget.style.display = 'block'
    } else {
      this.paneTarget.style.display = 'none'
    }
  }

  toggle(e) {
    if(this.stateValue == 'open') {
      this.stateValue = 'closed'
      e.target.classList.remove('toggle--open')
      e.target.classList.add('toggle--closed')
      this.paneTarget.style.display = 'none'
    } else {
      this.stateValue = 'open'
      e.target.classList.remove('toggle--closed')
      e.target.classList.add('toggle--open')
      this.paneTarget.style.display = 'block'
    }
  }
}
